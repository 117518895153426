<template>
  <div class="outer-wrapper">
    <home-carousel></home-carousel>
    <services></services>
    <missions></missions>
    <news></news>
  </div>
</template>
<script>
import homeCarousel from "./HomeCarousel.vue"
import services from "./Services.vue"
import news from "./News.vue"
import missions from "./Missions.vue"

export default {
  name: "indexContent",
  components: {
    homeCarousel,
    services,
    news,
    missions,
  },
  data() {
    return {
      
    };
  },
  methods: {
  },
  mounted(){
  }
};
</script>
<style scoped>

</style>