<template>
  <div class="section-wrapper">
    <section-title :titleText="titleObj"></section-title>
    <div class="sub-section-wrapper">
      <div class="left">
        <div class="inner-wrapper">
          <div
            class="ele-wrapper"
            v-for="(item, index) in contentItems"
            :key="index"
          >
            <div class="ele-inner">
              <div class="time-box">
                <p class="day">{{ getDay(item.pubdate) }}</p>
                <p class="yearAndMonth">{{ getMY(item.pubdate) }}</p>
              </div>
              <div class="content-box">
                <div class="title" @click="link('/news/' + item.id)">
                  {{ item.title }}
                </div>
                <div class="content">{{ item.description }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="inner-wrapper">
          <Carousel v-model="value1" loop autoplay>
            <CarouselItem v-for="item in items" :key="item">
              <img class="img" :src="item" alt="" />
            </CarouselItem>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import sectionTitle from "./SectionTitle.vue";
import dayjs from "dayjs";
export default {
  name: "news",
  components: {
    sectionTitle,
  },
  data() {
    return {
      titleObj: {
        cn: "新闻中心",
        en: "our news",
      },
      items: [
        process.env.BASE_URL + "img/index/news/b1.png",
        process.env.BASE_URL + "img/index/news/b2.png",
        process.env.BASE_URL + "img/index/news/b3.png",
        process.env.BASE_URL + "img/index/news/b4.png",
      ],
      contentItems: [],
      spin: false,
      Tpage: {
        currentPage: 1,
        currentSize: 4,
      },
      code: "news",
      lang: "中文",
      keyword: "",
      value1:0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    link: function (value) {
      location.href = value;
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + "/flist", {
          page: this.Tpage,
          askData: this.code,
          keyword: this.keyword,
          choosed: "all",
          lang: this.lang,
        })
        .then((response) => {
          this.contentItems = response.data.res_record;
          this.contentItems.sort((a, b) => {
            return dayjs(b.pubdate) - dayjs(a.pubdate);
          });
          this.spin = false;
        });
    },
    urlTrans(url) {
      // return url;
      if (url) {
        return this.$fileUrlFacWithoutRight(url);
      } else {
        return "";
      }
    },
    getDay(value) {
      return dayjs(value).format("DD");
    },
    getMY(value) {
      return dayjs(value).format("YYYY/MM");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
  padding: 20px 0 150px;
}

.sub-section-wrapper {
  max-width: 1260px;
  width: auto;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
}

.left,
.right {
  width: 50%;
  padding: 0 1.2%;
}

.inner-wrapper {
  width: 100%;
}

.ele-wrapper {
  height: 110px;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.ele-inner {
  display: flex;
  display: -webkit-flex;
}

.time-box {
  width: 90px;
  height: 90px;
  line-height: 26px;
  background-color: #f2f2f2;
  text-align: center;
  flex-shrink: 0;
}

.day {
  font-size: 30px;
  padding-top: 20px;
  font-family: 微软雅黑, Microsoft YaHei;
}

.yearAndMonth {
  font-size: 14px;
  line-height: 36px;
  font-family: 微软雅黑, Microsoft YaHei;
}

.content-box {
  padding: 5px 0 5px 30px;
}

.content-box .title {
  font-size: 18px;
  line-height: 18px;
  font-family: 微软雅黑, Microsoft YaHei;
  color: #333;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 5px;
}

.content-box .title:hover {
  color: #337ab7;
}

.content-box .content {
  color: #aaa;
  height: 38px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 13px;
  white-space: normal;
  line-height: 1.5;
}
</style>