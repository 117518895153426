<template>
<div class="section-wrapper">
   <section-title :titleText="titleObj"></section-title>
  <div class="sub-section-wrapper">
    <div class="wrapper-box">
      <div class="ele-item" v-for="(ele,index) in contentItems" :key="index">
        <div class="ele-inner-wrapper">
          <div class="ele-a">
            <img :src="ele.imgUrl" alt="" >
          </div>
          <div class="ele-b">
            <div class="b-des">
              <div class="b-des-title">
                {{ele.title}}
              </div>
              <div class="b-des-content">
                <p>{{ele.description}}</p>
              </div>
            </div>

          </div>
          <div class="ele-c">
            <div class="b-link">
              <p @click="link(ele.url)">
                查看详情 >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
 
</template>
<script>
import sectionTitle from "./SectionTitle.vue"
export default {
  name: "indexContent",
  components: {
    sectionTitle
  },
  data() {
    return {
      titleObj:{
        cn:"我们的服务",
        en:"our services"
      },
      contentItems: [
        {
          imgUrl: process.env.BASE_URL + "img/index/services/test.jpg",
          url: "/services_list/test",
          title: "检测试验",
          description:
            "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        },
        {
          imgUrl: process.env.BASE_URL + "img/index/services/evaluate.jpg",
          url: "/services_list/evaluate",
          title: "检验评估",
          description:
            "城市轨道交通运营安全评价；车辆服役安全评估；覆盖轨道交通系统从规划、设计、制造、建设、安装、调试、试运行、运营及维护阶段全生命周期的符合性评估。",
        },
        {
          imgUrl: process.env.BASE_URL + "img/index/services/certification.jpg",
          url: "/services_list/certification",
          title: "认证服务",
          description:
            "陆地交通设备领域自愿性产品认证（含安全认证）、城轨装备认证、服务认证、质量体系认证；轨道交通领域出口产品认证。",
        },
      ],
    };
  },
  methods: {
    link: function (value) {
      location.href = value;
    },
  },
  mounted() {},
};
</script>
<style scoped>

.section-wrapper{
  width:100%;
  padding: 20px 0 150px;
}

.sub-section-wrapper{
  max-width: 1260px;
    width: auto;
  margin:0 auto;
  overflow: hidden;
}
.wrapper-box{
  display:flex;
  display: -webkit-flex;
}

.ele-item{
  width:420px;
  padding: 0 15px;
}

.ele-inner-wrapper{
  border:1px solid #eee;
  cursor:pointer;
  padding: 10px;
}

.ele-a{
  overflow: hidden;
}

@keyframes it
{
0% {transform:scale(1.0);}
100% {transform:scale(1.1);}
}

.ele-a img:hover {
    animation: it 10s ease 0s forwards;
}

.ele-a{
  text-align: center;
}

.ele-a img{
  width:370px;
  height:210px;
  display: block;
}

.b-des{
  padding: 0 10px;
}

.b-des-title{
  font-size: ;
      color: #333;
    font-family: "Helvetica Neue",Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.333;
}

.ele-b{
  height: 180px;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.b-des-content{
  color: rgb(165, 165, 165);
    font-size: 14px;
    line-height: 184%;
    font-family: "微软雅黑";
}

.ele-c{
  padding: 0 10px;
}

.b-link{
  font-size: 14px;
    color: rgb(192, 0, 0);
    line-height: 184%;
}

</style>