<template>
<div class="section-wrapper">
   <section-title :titleText="titleObj"></section-title>
  <div class="sub-section-wrapper">
    <div class="left">
      <div class="inner-wrapper">
        <img :src="themeUrl" alt="" class="leftTheme">
      </div>
    </div>
    <div class="right">
      
      <div class="inner-wrapper">
        <div class="ele-wrapper" v-for="(item,index) in contentItems" :key="index">
          <div class="ele-inner">
            <div class="content-box">
              <div class="title">{{item.title}}</div>
              <div class="content">{{item.description}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
 
</template>
<script>
import sectionTitle from "./SectionTitle.vue"
export default {
  name: "mission",
  components: {
    sectionTitle
  },
  data() {
    return {
      titleObj:{
        cn:"我们的使命",
        en:"our mission"
      },
      themeUrl: process.env.BASE_URL + "img/index/scale.jpg",
      contentItems: [
        {
          imgUrl: process.env.BASE_URL + "img/index/risk.jpg",
          theSrc: "/risk_nav",
          title: "服务中国轨道交通“走出去”国家战略",
          description:
            "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        },
        {
          imgUrl: process.env.BASE_URL + "img/index/report.jpg",
          theSrc: "/conclusion/report_l",
          title: "支持中国轨道交通行业可持续发展",
          description:
            "城市轨道交通运营安全评价；车辆服役安全评估；覆盖轨道交通系统从规划、设计、制造、建设、安装、调试、试运行、运营及维护阶段全生命周期的符合性评估。",
        },
        {
          imgUrl: process.env.BASE_URL + "img/index/accident.jpg",
          theSrc: "/conclusion/accident_l",
          title: "建设国际一流的“国家级轨道交通装备检测、检验与认证中心”",
          description:
            "陆地交通设备领域自愿性产品认证（含安全认证）、城轨装备认证、服务认证、质量体系认证；轨道交通领域出口产品认证。",
        },
        {
          imgUrl: process.env.BASE_URL + "img/index/accident.jpg",
          theSrc: "/conclusion/accident_l",
          title: "开展轨道交通装备第三方检测认证与评估公共服务平台",
          description:
            "陆地交通设备领域自愿性产品认证（含安全认证）、城轨装备认证、服务认证、质量体系认证；轨道交通领域出口产品认证。",
        },
      ],
    };
  },
  methods: {
    link: function (value) {
      location.href = value;
    },
  },
  mounted() {},
};
</script>
<style scoped>

.section-wrapper{
  width:100%;
  padding: 20px 0 150px;
  background-color:#f3f7fa;
}

.sub-section-wrapper{
  max-width: 1260px;
  width: auto;
  margin:0 auto;
  overflow: hidden;
  display: flex;
}

.left{
  width:35%;
}

.right{
  width:65%;
}

.inner-wrapper{
  width:100%;
}

.right .inner-wrapper{
  display:flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}


.ele-wrapper{
  width:50%;
  padding: 5px;
  height:200px;
}
.ele-inner{
  border:1px solid #ccc;
  padding: 30px;
  height:100%;
}

.leftTheme{
  height:300px;
}

.content-box .title{
  color:#333;
  font-weight: 700;
  font-size: 18px;
}

.content-box .content{
  text-align: justify;
  padding: 10px 0 0px 5px;
  color:#999;
  font-size: 13px;
}


.ele-wrapper:hover .content{
  color:#444;
}

</style>