<template>
    <div class="outer-wrapper">
        <Carousel v-model="value1" loop autoplay>
            <CarouselItem v-for="item in items" :key="item">
                <img class="img" :src="item" alt="" />
            </CarouselItem>
        </Carousel>
    </div>

</template>
<script>
    export default {
        name: "homeCarousel",
        data() {
            return {
                value1:0,
                items: [process.env.BASE_URL + "img/index/banner/b1.png",
                process.env.BASE_URL + "img/index/banner/b2.png",
                process.env.BASE_URL + "img/index/banner/b3.png",
                process.env.BASE_URL + "img/index/banner/b4.png",
                ],
            }
        },
        methods: {

        },
    }
</script>
<style scoped>
    .outer-wrapper {
        margin: 0 auto;
        margin-bottom:0px;
        width: 100%;
        text-align: center;
    }
    .img{
        width:100%;
        height:auto;
        display: block;
    }
</style>