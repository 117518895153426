<template>
<div class="outer-wrapper">
   <div>
    <p style="text-align: center">
      <span style="font-size: 38px; color: rgb(0, 32, 96)"
        ><strong
          ><span
            style="
              font-family: 微软雅黑;
              line-height: 150%;
            "
            ori-font-size="28px"
            ori-line-height="150%"
            >{{titleText.cn}}</span
          ></strong
        ></span
      >
    </p>
    <p style="text-align: center">
      <span
        style="
          font-family: arial, helvetica, sans-serif;
          color: rgb(89, 89, 89);
          font-size: 20px;
          line-height: 150%;
        "
        ori-font-size="14px"
        ori-line-height="150%"
        >{{titleText.en}}</span
      >
    </p>
    <p style="text-align: center">
      <span style="color: rgb(192, 0, 0)"
        ><strong><span style="font-family: 微软雅黑">——</span></strong></span
      >
    </p>
  </div>
</div>
 
</template>
<script>
export default {
  name: "sectionTitle",
  props: {
    titleText: {
      type: Object,
      required: true,
      default(){
        return {
          cn:"未命名",
          en:"null"
        };
      }
    },
  },
  data() {
    return {
    };
  },
  methods: {
    link: function (value) {
      location.href = value;
    },
  },
  mounted() {},
};
</script>
<style scoped>

.outer-wrapper{
  width:100%;
  padding: 50px 0;
}

.sub-section-wrapper{
  max-width: 1260px;
    width: auto;
  margin:0 auto;
  overflow: hidden;
}
.wrapper-box{
  display:flex;
  display: -webkit-flex;
  margin-top:30px;
}

.ele-item{
  width:420px;
  padding: 0 15px;
}

.ele-inner-wrapper{
  border:1px solid #eee;
  padding: 10px;

}

.ele-a{
  text-align: center;
}

.ele-a img{
  width:370px;
  height:210px;
}

.b-des{
  padding: 0 10px;
}

.b-des-title{
  font-size: ;
      color: #333;
    font-family: "Helvetica Neue",Helvetica, Arial,Tahoma,"Microsoft YaHei", "PingFang SC", "Hiragino Sans GB", "Heiti SC", "WenQuanYi Micro Hei", sans-serif;
    outline: none;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 18px;
    text-transform: uppercase;
    line-height: 1.333;
}

.ele-b{
  height: 180px;
  overflow: hidden;
}

.b-des-content{
  color: rgb(165, 165, 165);
    font-size: 14px;
    line-height: 184%;
    font-family: "微软雅黑";
}

.ele-c{
  padding: 0 10px;
}

.b-link{
  font-size: 14px;
    color: rgb(192, 0, 0);
    line-height: 184%;
}

</style>